import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue,
  Divider,
  VStack,
  HStack,
  Icon,
  Circle,
} from '@chakra-ui/react';
import { FaBookReader, FaDollarSign, FaRocket, FaUserShield } from 'react-icons/fa';
import faqData from '../assets/data/faqs.json'; // Importamos el archivo JSON

// Mapeamos los iconos manualmente ya que en JSON no podemos almacenar funciones como componentes
const iconMap = {
  FaBookReader: FaBookReader,
  FaDollarSign: FaDollarSign,
  FaRocket: FaRocket,
  FaUserShield: FaUserShield,
};

const Faq = () => {
  // Estilos adaptativos a los modos de color
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const answerColor = useColorModeValue('gray.700', 'gray.300');
  const headingColor = useColorModeValue('teal.600', 'teal.300');

  return (
    <Box bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">
      
      <Helmet>
        <title>Preguntas Frecuentes - Biografai</title>
        <meta name="description" content="Encuentra respuestas a las preguntas más comunes sobre Biografai y la creación de biografías personalizadas." />
        
        {/* Open Graph */}
        <meta property="og:title" content="Preguntas Frecuentes - Biografai" />
        <meta property="og:description" content="Resuelve tus dudas sobre Biografai, la plataforma de biografías personalizadas." />
        <meta property="og:image" content="%PUBLIC_URL%/images/faq-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/faq" />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Preguntas Frecuentes - Biografai" />
        <meta name="twitter:description" content="Resuelve tus dudas sobre la creación de biografías en Biografai." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/faq-twitter-image.jpg" />
      </Helmet>

      <Container maxW={'6xl'}>
        {/* Título y explicación inicial */}
        <VStack spacing={4} mb={12} textAlign="center">
          <Heading as="h1" size="2xl" color={headingColor}>
            Preguntas Frecuentes
          </Heading>
          <Text fontSize="lg" color={answerColor} maxW="3xl">
            ¿Tienes dudas? Encuentra aquí las respuestas a las preguntas más comunes sobre Biografai, nuestra plataforma de biografías personalizadas. Si no encuentras lo que buscas, contáctanos o visita nuestra sección completa de <Text as="span" fontWeight="bold">FAQs</Text>.
          </Text>
        </VStack>

        {/* Sección de categorías de FAQ */}
        {faqData.faqCategories.map((category, index) => (
          <Box key={index} mb={12}>
            {/* Título de la categoría con icono */}
            <HStack spacing={3} mb={5}>
              <Circle size="40px" bg="teal.500" color="white">
                <Icon as={iconMap[category.icon]} w={6} h={6} />
              </Circle>
              <Heading as="h2" size="lg" textAlign={'left'} color={headingColor}>
                {category.category}
              </Heading>
            </HStack>

            <Accordion allowMultiple>
              {category.faqs.map((faq, i) => (
                <FaqItem key={i} question={faq.question} answer={faq.answer} />
              ))}
            </Accordion>

            {/* Divisor entre secciones */}
            {index < faqData.faqCategories.length - 1 && <Divider mt={10} />}
          </Box>
        ))}

      </Container>
    </Box>
  );
};

// Componente de cada FAQ individual
const FaqItem = ({ question, answer }) => {
  const expandedBg = useColorModeValue('teal.100', 'teal.600');
  const expandedColor = useColorModeValue('teal.800', 'white');
  const answerColor = useColorModeValue('gray.700', 'gray.300');

  return (
    <AccordionItem border={'none'} mb={5}>
      <h2>
        <AccordionButton
          _expanded={{
            bg: expandedBg,
            color: expandedColor,
          }}
          px={4}
          py={2}
          rounded={'md'}
        >
          <Box flex="1" textAlign="left" fontWeight={'medium'} fontSize={'lg'}>
            {question}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} px={4}>
        <Text color={answerColor}>{answer}</Text>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default Faq;
