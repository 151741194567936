// src/pages/PrivacyPolicy.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Heading,
  Text,
  Stack,
  VStack,
  useColorModeValue,
  Link,
  Divider,
  Container,
} from '@chakra-ui/react';

const PrivacyPolicy = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.300');
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const dividerColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Box bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">
      
      <Helmet>
        <title>Política de Privacidad - Biografai</title>
        <meta name="description" content="Lee nuestra Política de Privacidad para entender cómo Biografai protege y gestiona tus datos personales." />
        
        {/* Open Graph */}
        <meta property="og:title" content="Política de Privacidad - Biografai" />
        <meta property="og:description" content="Descubre cómo Biografai gestiona tus datos de manera segura y responsable." />
        <meta property="og:image" content="%PUBLIC_URL%/images/privacy-policy-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/privacy-policy" />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Política de Privacidad - Biografai" />
        <meta name="twitter:description" content="Descubre cómo Biografai protege tu privacidad y datos personales." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/privacy-policy-twitter-image.jpg" />
      </Helmet>

      <Container maxW="container.lg">
        <VStack spacing={8} align="flex-start">
          {/* Título Principal */}
          <Heading as="h1" size="2xl" color={headingColor}>
            Política de Cookies
          </Heading>

          <Text fontSize="lg" color={textColor}>
            Esta política explica cómo Biografai utiliza cookies en nuestro sitio web para mejorar
            su experiencia de usuario. Al navegar por nuestro sitio, aceptas el uso de cookies de
            acuerdo con esta política.
          </Text>

          {/* Información Clásica sobre las Cookies */}
          <Stack spacing={6}>
            <Heading as="h2" size="lg" color={headingColor}>
              ¿Qué son las cookies?
            </Heading>
            <Text color={textColor} fontSize="md">
              Las cookies son pequeños archivos de texto que se almacenan en tu dispositivo (como tu
              ordenador o teléfono móvil) cuando visitas un sitio web. Son ampliamente utilizadas
              para hacer que los sitios web funcionen o para que funcionen de manera más eficiente,
              así como para proporcionar información a los propietarios del sitio.
            </Text>

            <Divider borderColor={dividerColor} />

            <Heading as="h2" size="lg" color={headingColor}>
              Tipos de cookies que utilizamos
            </Heading>
            <Text color={textColor} fontSize="md">
              En Biografai utilizamos los siguientes tipos de cookies:
            </Text>

            <Stack spacing={4} pl={4}>
              <Box>
                <Heading as="h3" size="md" color={headingColor}>
                  Cookies esenciales
                </Heading>
                <Text color={textColor}>
                  Estas cookies son necesarias para el funcionamiento básico del sitio web. Permiten
                  funciones esenciales como la navegación por la página y el acceso a áreas seguras
                  del sitio.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" size="md" color={headingColor}>
                  Cookies de rendimiento
                </Heading>
                <Text color={textColor}>
                  Utilizamos estas cookies para recopilar información sobre cómo los visitantes usan
                  nuestro sitio web. Nos ayudan a entender qué páginas son las más populares y cómo
                  se navega por el sitio, lo que nos permite mejorar la funcionalidad y la
                  experiencia del usuario.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" size="md" color={headingColor}>
                  Cookies de funcionalidad
                </Heading>
                <Text color={textColor}>
                  Estas cookies permiten que el sitio recuerde las elecciones que haces (como tu
                  nombre de usuario, idioma o región) y proporcionan características mejoradas y más
                  personalizadas.
                </Text>
              </Box>

              <Box>
                <Heading as="h3" size="md" color={headingColor}>
                  Cookies de terceros
                </Heading>
                <Text color={textColor}>
                  En algunos casos, utilizamos cookies proporcionadas por terceros, como servicios
                  de análisis de tráfico web o integración con redes sociales. Estos terceros pueden
                  utilizar la información obtenida para ofrecer anuncios personalizados o medir la
                  efectividad de campañas publicitarias.
                </Text>
              </Box>
            </Stack>

            <Divider borderColor={dividerColor} />

            <Heading as="h2" size="lg" color={headingColor}>
              ¿Cómo puedes controlar las cookies?
            </Heading>
            <Text color={textColor} fontSize="md">
              Puedes controlar y/o eliminar cookies en cualquier momento cambiando la configuración
              de tu navegador. Ten en cuenta que al eliminar o bloquear cookies, algunas
              funcionalidades de este sitio podrían verse afectadas.
            </Text>

            <Text color={textColor} fontSize="md">
              Para más información sobre cómo gestionar y eliminar cookies, visita{' '}
              <Link
                href="https://www.allaboutcookies.org/es/"
                color="teal.300"
                isExternal
              >
                All About Cookies (en español)
              </Link>.
            </Text>

            <Divider borderColor={dividerColor} />

            <Heading as="h2" size="lg" color={headingColor}>
              Cambios en nuestra política de cookies
            </Heading>
            <Text color={textColor} fontSize="md">
              Biografai se reserva el derecho de actualizar esta política de cookies para reflejar
              cambios en nuestras prácticas. Te recomendamos revisar esta página periódicamente para
              estar al tanto de las actualizaciones.
            </Text>
          </Stack>

          <Divider borderColor={dividerColor} />

          {/* Pie de Página de la Política */}
          <Text fontSize="sm" color={textColor} textAlign="center">
            Última actualización: Octubre 2024
          </Text>
        </VStack>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;
