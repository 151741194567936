// src/pages/Blog.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  SimpleGrid,
  Image,
  Text,
  Heading,
  Link as ChakraLink,
  VStack,
  useColorModeValue,
  Container,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
  Divider
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { SearchIcon } from '@chakra-ui/icons';
import posts from '../../assets/data/posts.json';

const Blog = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBg = useColorModeValue('white', 'gray.700');
  const cardHover = useColorModeValue('gray.100', 'gray.600');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  // Estado para manejar la búsqueda
  const [searchTerm, setSearchTerm] = useState('');

  // Filtra los posts en base al término de búsqueda
  const filteredPosts = posts.filter((post) =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">
      
      <Helmet>
        <title>Blog - Biografai</title>
        <meta name="description" content="Lee artículos sobre la importancia de preservar la historia personal y cómo crear tu legado en el blog de Biografai." />
        {/* Open Graph */}
        <meta property="og:title" content="Blog - Biografai" />
        <meta property="og:description" content="Explora temas sobre biografías, legados y preservación de historia personal en el blog de Biografai." />
        <meta property="og:image" content="%PUBLIC_URL%/images/blog-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/blog" />
        <meta property="og:type" content="website" />
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Blog - Biografai" />
        <meta name="twitter:description" content="Descubre cómo preservar tu historia y legado en el blog de Biografai." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/blog-twitter-image.jpg" />
      </Helmet>

      <Container maxW="container.lg">
        <Heading as="h1" size="2xl" textAlign="center" mb={4} color={textColor}>
          Blog de Biografai
        </Heading>
        <Text textAlign="center" mb={10} color="gray.500" maxW="600px" mx="auto">
          Bienvenido al Blog de Biografai, donde compartimos consejos, historias inspiradoras y las últimas novedades sobre cómo preservar tu legado personal de manera innovadora y accesible.
        </Text>

        {/* Barra de búsqueda */}
        <InputGroup mb={8} maxW="600px" mx="auto">
          <InputLeftElement pointerEvents="none">
            <Icon as={SearchIcon} color="gray.500" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder="Buscar artículos..."
            variant="filled"
            bg={cardBg}
            color={textColor}
            onChange={(e) => setSearchTerm(e.target.value)}
            boxShadow={
              filteredPosts.length === 0
                ? "0 4px 10px rgba(255, 0, 0, 0.5)" // Sombra roja si no hay resultados
                : "0 4px 10px rgba(72, 187, 120, 0.3)" // Sombra teal si hay resultados
            }
            _focus={{
              boxShadow:
                filteredPosts.length === 0
                  ? "0 4px 10px rgba(255, 0, 0, 0.7)" // Sombra roja más intensa en foco si no hay resultados
                  : "0 4px 10px rgba(72, 187, 120, 0.5)" // Sombra teal más intensa en foco si hay resultados
            }}
          />
        </InputGroup>

        <Divider borderColor="gray.300" my={4} />

        {filteredPosts.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
            {filteredPosts.map((post) => (
              <ChakraLink
                as={Link}
                to={`/post/${post.slug}`}
                key={post.id}
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
              >
                <Box
                  bg={cardBg}
                  boxShadow="md"
                  borderRadius="lg"
                  overflow="hidden"
                  transition="transform 0.2s, box-shadow 0.2s, background-color 0.2s"
                  _hover={{ transform: 'scale(1.05)', boxShadow: 'lg', bg: cardHover }}
                  height="350px"
                  display="flex"
                  flexDirection="column"
                >
                  <Image
                    src={post.thumbnail}
                    alt={post.title}
                    height="150px"
                    width="100%"
                    objectFit="cover"
                  />
                  <VStack align="start" p={4} spacing={2} flex="1">
                    <Heading as="h3" size="md" color={textColor} noOfLines={2}>
                      {post.title}
                    </Heading>
                    <Text color="gray.500" noOfLines={3} textAlign="justify">
                      {post.description}
                    </Text>
                    <Text fontSize="sm" color="teal.500" mt="auto">
                      Leer más
                    </Text>
                  </VStack>
                </Box>
              </ChakraLink>
            ))}
          </SimpleGrid>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Text textAlign="center" color="gray.500" fontSize="lg">
              No se encontraron artículos para "{searchTerm}".
            </Text>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Blog;
