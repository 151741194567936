// Importaciones
import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Button,
  Stack,
  Image,
  Icon,
  useColorModeValue,
  SimpleGrid,
  Container,
  Avatar,
  HStack,
  VStack
} from '@chakra-ui/react';
import { FaTools, FaHeadset, FaPlug } from 'react-icons/fa';
import HeroImage from '../assets/images/hero2.png'
import UJILogo from '../assets/images/uji.png'

// Datos de testimonios
const testimonials = [
  {
    name: 'Juan Pérez',
    role: 'CEO de Empresa X',
    content:
      'El servicio ha sido excepcional. Gracias a Mi Sitio Web, hemos aumentado nuestra productividad.',
    avatar: 'https://via.placeholder.com/100',
  },
  {
    name: 'María Gómez',
    role: 'Directora de Marketing en Empresa Y',
    content:
      'La integración fue sencilla y el soporte al cliente es insuperable. ¡Altamente recomendado!',
    avatar: 'https://via.placeholder.com/100',
  },
  {
    name: 'María Gómez',
    role: 'Directora de Marketing en Empresa Y',
    content:
      'La integración fue sencilla y el soporte al cliente es insuperable. ¡Altamente recomendado!',
    avatar: 'https://via.placeholder.com/100',
  },
  {
    name: 'María Gómez',
    role: 'Directora de Marketing en Empresa Y',
    content:
      'La integración fue sencilla y el soporte al cliente es insuperable. ¡Altamente recomendado!',
    avatar: 'https://via.placeholder.com/100',
  },
];

// Datos de clientes
const clients = [
  {
    name: 'Cliente A',
    logo: UJILogo,
  },
  // {
  //   name: 'Cliente B',
  //   logo: 'https://via.placeholder.com/150x50?text=Logo+B',
  // },
  // {
  //   name: 'Cliente C',
  //   logo: 'https://via.placeholder.com/150x50?text=Logo+C',
  // },
  // Añade más clientes si lo deseas
];

const Home = () => {
  // Colores adaptativos al modo claro/oscuro
  const heroBg = useColorModeValue('gray.50', 'gray.800');
  const heroTextColor = useColorModeValue('gray.700', 'gray.300');
  const featuresBg = useColorModeValue('white', 'gray.900');
  const testimonialsBg = useColorModeValue('gray.50', 'gray.800');
  const clientsBg = useColorModeValue('white', 'gray.900');
  const ctaBg = useColorModeValue('teal.500', 'teal.700');

  return (
    <Box>
      {/* Sección Hero */}
      <Box bg={heroBg} py={16} px={4} display="flex" flexDirection="column" flex="1">
        <Container maxW={'container.lg'}>
          <Flex align={'center'} direction={{ base: 'column', md: 'row' }}>
            <Stack flex={1} spacing={5} mb={{ base: 10, md: 0 }}>
              <Heading as="h1" size="2xl" lineHeight={1.2} fontWeight="bold">
                Bienvenido a{' '}
                <Text as="span" color="teal.500">
                  Biografai
                </Text>
              </Heading>
              <Text color={heroTextColor} fontSize={'lg'}>
              Nuestra inteligencia artificial transforma tu vida en una biografía personalizada. 
              Elige el formato que más te guste y comparte tus recuerdos con tus seres queridos, ahora y en el futuro.
              </Text>
              <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
                <Button colorScheme={'teal'} size="lg">
                  Comenzar
                </Button>
                <Button variant="outline" colorScheme={'teal'} size="lg">
                  Más Información
                </Button>
              </Stack>
            </Stack>
            <Flex flex={1} justify={'center'} align={'center'}>
              <Image
                // src={'https://via.placeholder.com/500'}
                src={HeroImage}
                alt={'Imagen Hero'}
                w={'full'}
                rounded={'md'}
              />
            </Flex>
          </Flex>
        </Container>
      </Box>

      {/* Sección de Características */}
      <Box py={20} bg={featuresBg}>
        <Container maxW="6xl">
          <Heading as="h2" size="xl" textAlign="center" mb={10}>
            Nuestras Características
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            <Feature
              icon={FaTools}
              title="Servicio de Alta Calidad"
              text="Proporcionamos servicios de primer nivel para garantizar la satisfacción de nuestros clientes."
            />
            <Feature
              icon={FaHeadset}
              title="Soporte 24/7"
              text="Nuestro equipo de soporte está disponible en todo momento para ayudarte."
            />
            <Feature
              icon={FaPlug}
              title="Integraciones Fáciles"
              text="Integra nuestras soluciones con tus sistemas existentes sin complicaciones."
            />
          </SimpleGrid>
        </Container>
      </Box>

      {/* Sección de Testimonios */}
      <Box py={20} bg={testimonialsBg}>
        <Container maxW={'6xl'}>
          <Heading as="h2" size="xl" textAlign={'center'} mb={10}>
            Lo que dicen algunos de nuestros clientes
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index} {...testimonial} />
            ))}
          </SimpleGrid>
        </Container>
      </Box>

      {/* Sección de Clientes Destacados */}
      {/* <Box py={20} bg={clientsBg}>
        <Container maxW={'6xl'} textAlign={'center'}>
          <Heading as="h2" size="xl" mb={10}>
            Quienes confían en nosotros
          </Heading>
          <HStack spacing={8} justify={'center'}>
            {clients.map((client, index) => (
              <Image
                key={index}
                src={client.logo}
                alt={client.name}
                w={{ base: '100px', md: '150px' }}
              />
            ))}
          </HStack>
        </Container>
      </Box> */}

      {/* Sección de Llamada a la Acción */}
      {/* <Box bg={ctaBg} py={20}>
        <Container maxW={'6xl'}>
          <Flex align={'center'} direction={{ base: 'column', md: 'row' }}>
            <Box flex={1}>
              <Heading as="h2" size="xl" color={'white'}>
                ¿Listo para empezar?
              </Heading>
              <Text color={'white'} mt={4} fontSize={'lg'}>
                Únete a nosotros hoy y lleva tu negocio al siguiente nivel.
              </Text>
            </Box>
            <Button
              colorScheme={'whiteAlpha'}
              variant="solid"
              size="lg"
              mt={{ base: 8, md: 0 }}
              ml={{ md: 8 }}
            >
              Regístrate Ahora
            </Button>
          </Flex>
        </Container>
      </Box> */}
    </Box>
  );
};

// Componente Feature
const Feature = ({ title, text, icon }) => {
  const textColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Stack align="center">
      <Flex
        w={16}
        h={16}
        align="center"
        justify="center"
        color="white"
        rounded="full"
        bg="teal.500"
        mb={1}
      >
        <Icon as={icon} w={8} h={8} />
      </Flex>
      <Text fontWeight="600" fontSize="lg" textAlign="center">
        {title}
      </Text>
      <Text color={textColor} textAlign="center">
        {text}
      </Text>
    </Stack>
  );
};

// Componente Testimonial
const Testimonial = ({ name, role, content, avatar }) => {
  const contentColor = useColorModeValue('gray.700', 'gray.300');
  const roleColor = useColorModeValue('gray.600', 'gray.400');

  return (
    <Stack spacing={5} p={5} shadow="md" borderWidth="1px" rounded={'md'}>
      <Text color={contentColor} fontSize={'lg'}>
        "{content}"
      </Text>
      <HStack spacing={3} alignItems={'center'}>
        <Avatar src={avatar} name={name} />
        <VStack align={'start'} spacing={0}>
          <Text fontWeight={600}>{name}</Text>
          <Text color={roleColor}>{role}</Text>
        </VStack>
      </HStack>
    </Stack>
  );
};

export default Home;
