import {
    useColorModeValue,
    FormControl,
    FormLabel,
    Switch,
    Button,
    Stack,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';

const SettingsModal = ({ isOpen, onClose }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.700', 'gray.200');
  
    return (
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay
          bg="blackAlpha.600"
          backdropFilter="blur(10px)"
        />
        <ModalContent bg={bgColor}>
          <ModalHeader>Ajustes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={6}>
              {/* Ejemplo de formulario de ajustes */}
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0" color={textColor}>
                  Recibir notificaciones por correo electrónico
                </FormLabel>
                <Switch id="email-alerts" colorScheme="teal" />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0" color={textColor}>
                  Recibir notificaciones por correo electrónico
                </FormLabel>
                <Switch id="email-alerts" colorScheme="teal" />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0" color={textColor}>
                  Recibir notificaciones por correo electrónico
                </FormLabel>
                <Switch id="email-alerts" colorScheme="teal" />
              </FormControl>
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0" color={textColor}>
                  Recibir notificaciones por correo electrónico
                </FormLabel>
                <Switch id="email-alerts" colorScheme="teal" />
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={onClose}>
              Guardar Cambios
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
};

export default SettingsModal;