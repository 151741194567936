// Login.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  IconButton,
  useColorModeValue,
  useToast,
  Divider,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  // Variables de color adaptativas al modo claro/oscuro
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const formBgColor = useColorModeValue('white', 'gray.700');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulación de inicio de sesión
    setTimeout(() => {
      setIsSubmitting(false);

      if (email === 'user@example.com' && password === 'password') {
        toast({
          title: 'Inicio de sesión exitoso',
          description: 'Has iniciado sesión correctamente.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Aquí puedes redirigir al usuario al dashboard u otra página
      } else {
        toast({
          title: 'Error al iniciar sesión',
          description: 'Correo electrónico o contraseña incorrectos.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }, 1500);
  };

  const handleGoogleLogin = () => {
    // Lógica para iniciar sesión con Google
    // Por ejemplo, puedes utilizar Firebase Auth, Auth0, o tu propia implementación
    // Aquí simplemente mostraremos un mensaje simulado

    toast({
      title: 'Inicio de sesión con Google',
      description: 'Funcionalidad de inicio de sesión con Google.',
      status: 'info',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex align="center" justify="center" bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">
      
      <Helmet>
        <title>Iniciar Sesión - Biografai</title>
        <meta name="description" content="Inicia sesión en Biografai para crear, gestionar y compartir tu biografía personal." />
        
        {/* Open Graph */}
        <meta property="og:title" content="Iniciar Sesión - Biografai" />
        <meta property="og:description" content="Inicia sesión en Biografai y continúa creando tu legado personal." />
        <meta property="og:image" content="%PUBLIC_URL%/images/login-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/login" />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Iniciar Sesión - Biografai" />
        <meta name="twitter:description" content="Ingresa a tu cuenta en Biografai y mantén viva tu historia." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/login-twitter-image.jpg" />
      </Helmet>

      <Box
        bg={formBgColor}
        p={8}
        rounded="lg"
        boxShadow="lg"
        maxW="md"
        w="full"
      >
        <Stack spacing={4} mb={6}>
          <Heading as="h1" size="lg" textAlign="center">
            Iniciar Sesión
          </Heading>
          <Text fontSize="lg" color="gray.600" textAlign="center">
            Introduce tus credenciales para acceder a tu cuenta
          </Text>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Correo Electrónico</FormLabel>
              <Input
                type="email"
                placeholder="tuemail@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Contraseña</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Ingresa tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement h="full">
                  <IconButton
                    variant="ghost"
                    aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={() => setShowPassword((show) => !show)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button
              type="submit"
              colorScheme="teal"
              size="lg"
              fontSize="md"
              isLoading={isSubmitting}
            >
              Iniciar Sesión
            </Button>
            <Divider />
            <Button
              variant="outline"
              leftIcon={<FcGoogle />}
              size="lg"
              fontSize="md"
              onClick={handleGoogleLogin}
            >
              Iniciar sesión con Google
            </Button>
          </Stack>
        </form>
        <Text mt={6} textAlign="center">
          ¿No tienes una cuenta?{' '}
          <Button
            as={Link}
            to="/register"
            variant="link"
            colorScheme="teal"
          >
            Regístrate
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default Login;
