// Layout.js
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';
import Navbar from './Navbar';
import Footer from './Footer';
import CookieConsent from './CookieConsent';

const Layout = () => {
  return (
    <Flex direction="column" minHeight="100vh">
      <Navbar />

      <CookieConsent />

      <Box flex="1" display="flex" flexDirection="column">
        <Outlet />
      </Box>
      
      <Footer />
    </Flex>
  );
};

export default Layout;
