// AppRoutes.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Layout from '../components/Layout';

import Home from '../pages/Home';
import FAQ from '../pages/FAQ';
import Pricing from '../pages/Prices';
import AboutUs from '../pages/AboutUs';
import Dashboard from '../pages/Dashboard/Dashboard';
import PrivacyPolicy from '../pages/PrivacyPolicy';

import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';

import Blog from '../pages/Blog/Blog';
import Post from '../pages/Blog/Post';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        {/* Rutas que no utilizan el Layout */}
        <Route path="/dashboard/*" element={<Dashboard />} />

        {/* Rutas que utilizan el Layout */}
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/prices" element={<Pricing />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/cookie-policy" element={<PrivacyPolicy />} />
          <Route path="/post/:slug" element={<Post />} />
          {/* Ruta para manejar páginas no encontradas */}
          <Route path="*" element={<Home />} />
          
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
