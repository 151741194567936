// Register.js
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Button,
  Stack,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  IconButton,
  useColorModeValue,
  useToast,
  Divider,
  Link as ChakraLink,
  SimpleGrid, // Asegúrate de importar SimpleGrid
  Select,      // Importa Select
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';

const Register = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [nombre, setNombre] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [edad, setEdad] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  // Variables de color adaptativas al modo claro/oscuro
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const formBgColor = useColorModeValue('white', 'gray.700');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulación de registro
    setTimeout(() => {
      setIsSubmitting(false);

      if (nombre && apellidos && edad && email && password) {
        toast({
          title: 'Registro exitoso',
          description: 'Tu cuenta ha sido creada correctamente.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // Aquí puedes redirigir al usuario al dashboard u otra página
      } else {
        toast({
          title: 'Error en el registro',
          description: 'Por favor, completa todos los campos requeridos.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }, 1500);
  };

  const handleGoogleRegister = () => {
    // Lógica para registrarse con Google
    // Por ejemplo, puedes utilizar Firebase Auth, Auth0, o tu propia implementación
    // Aquí simplemente mostraremos un mensaje simulado

    toast({
      title: 'Registro con Google',
      description: 'Funcionalidad de registro con Google en desarrollo.',
      status: 'info',
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <Flex align="center" justify="center" bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">
      
      <Helmet>
        <title>Registrarse - Biografai</title>
        <meta name="description" content="Regístrate en Biografai y comienza a crear tu biografía personal para preservar tu legado." />
        
        {/* Open Graph */}
        <meta property="og:title" content="Registrarse - Biografai" />
        <meta property="og:description" content="Únete a Biografai y empieza a crear una biografía que perdure en el tiempo." />
        <meta property="og:image" content="%PUBLIC_URL%/images/register-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/register" />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Registrarse - Biografai" />
        <meta name="twitter:description" content="Regístrate en Biografai y preserva tu historia en una biografía memorable." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/register-twitter-image.jpg" />
      </Helmet>

      <Box
        bg={formBgColor}
        p={8}
        rounded="lg"
        boxShadow="lg"
        maxW="md"
        w="full"
      >
        <Stack spacing={4} mb={6}>
          <Heading as="h1" size="lg" textAlign="center">
            Crear Cuenta
          </Heading>
          <Text fontSize="md" color="gray.600" textAlign="center">
            Regístrate para acceder a todas las funcionalidades
          </Text>
        </Stack>
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            {/* Agrupación de Nombre y Apellidos */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
              <FormControl id="nombre" isRequired>
                <FormLabel>Nombre</FormLabel>
                <Input
                  type="text"
                  placeholder="Tu nombre"
                  value={nombre}
                  onChange={(e) => setNombre(e.target.value)}
                />
              </FormControl>
              <FormControl id="apellidos" isRequired>
                <FormLabel>Apellidos</FormLabel>
                <Input
                  type="text"
                  placeholder="Tus apellidos"
                  value={apellidos}
                  onChange={(e) => setApellidos(e.target.value)}
                />
              </FormControl>
            </SimpleGrid>

            {/* Campo de Edad como Select */}
            <FormControl id="edad" isRequired>
              <FormLabel>Edad</FormLabel>
              <Select
                placeholder="Selecciona tu edad"
                value={edad}
                onChange={(e) => setEdad(e.target.value)}
              >
                {Array.from({ length: 83 }, (_, i) => 18 + i).map((age) => (
                  <option key={age} value={age}>
                    {age}
                  </option>
                ))}
              </Select>
            </FormControl>

            {/* Campo de Correo Electrónico */}
            <FormControl id="email" isRequired>
              <FormLabel>Correo Electrónico</FormLabel>
              <Input
                type="email"
                placeholder="tuemail@example.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>

            {/* Campo de Contraseña */}
            <FormControl id="password" isRequired>
              <FormLabel>Contraseña</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Ingresa tu contraseña"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <InputRightElement h="full">
                  <IconButton
                    variant="ghost"
                    aria-label={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={() => setShowPassword((show) => !show)}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            {/* Botón de Registro */}
            <Button
              type="submit"
              colorScheme="teal"
              size="lg"
              fontSize="md"
              isLoading={isSubmitting}
            >
              Registrarse
            </Button>

            {/* Separador */}
            <Divider />

            {/* Botón de Registro con Google */}
            <Button
              variant="outline"
              leftIcon={<FcGoogle />}
              size="lg"
              fontSize="md"
              onClick={handleGoogleRegister}
            >
              Registrarse con Google
            </Button>
          </Stack>
        </form>

        {/* Enlace para Iniciar Sesión */}
        <Text mt={6} textAlign="center">
          ¿Ya tienes una cuenta?{' '}
          <Button
            as={Link}
            to="/login"
            variant="link"
            colorScheme="teal"
          >
            Inicia Sesión
          </Button>
        </Text>
      </Box>
    </Flex>
  );
};

export default Register;
