// src/pages/Post.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Heading,
  Text,
  Image,
  Divider,
  useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Link as ChakraLink,
  Container,
  Tag,
  HStack,
  Flex,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { FaCalendarAlt } from 'react-icons/fa';
import posts from '../../assets/data/posts.json';

const Post = () => {
  const { slug } = useParams();
  const post = posts.find((p) => p.slug === slug);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const contentBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const linkColor = useColorModeValue('teal.500', 'teal.300');
  const tagBg = useColorModeValue('teal.100', 'teal.700');

  if (!post) {
    return (
      <Box bg={bgColor} py={20} px={6} minH="100vh">
        <Heading as="h2" size="xl" textAlign="center" color={textColor}>
          Publicación No Encontrada
        </Heading>
        <Text textAlign="center" mt={4} color="gray.500">
          La publicación que buscas no existe.
        </Text>
      </Box>
    );
  }

  // Generar Tabla de Contenido
  const toc = post.content
    .filter((section) => section.type === 'heading' && section.level === 2)
    .map((section) => ({
      text: section.text,
      id: section.text.toLowerCase().replace(/ /g, '-'),
    }));

  // Agregar IDs a las secciones de encabezado
  const contentWithIds = post.content.map((section) => {
    if (section.type === 'heading' && section.level === 2) {
      return { ...section, id: section.text.toLowerCase().replace(/ /g, '-') };
    }
    return section;
  });

  return (
    <Box bg={bgColor} py={16} px={4} minH="100vh">
      
      <Helmet>
        <title>{post.title} - Biografai Blog</title>
        <meta name="description" content={post.excerpt} />
        
        {/* Open Graph */}
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.image} />
        <meta property="og:url" content={`https://www.biografai.com/post/${post.slug}`} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post.title} />
        <meta name="twitter:description" content={post.excerpt} />
        <meta name="twitter:image" content={post.image} />
      </Helmet>

      <Container maxW="container.lg">
        {/* Breadcrumb de navegación */}
        <Breadcrumb mb={6} fontSize={{ base: 'sm', md: 'md' }} isTruncated>
          <BreadcrumbItem>
            <BreadcrumbLink as={ChakraLink} href="/" color={linkColor}>
              Inicio
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink as={ChakraLink} href="/blog" color={linkColor}>
              Blog
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink>{post.title}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {/* Título del post */}
        <Heading as="h1" size={{ base: 'xl', md: '2xl' }} mb={4} color={textColor} textAlign="center">
          {post.title}
        </Heading>

        {/* Información del post */}
        <Flex justifyContent="center" mb={8} alignItems="center" flexWrap="wrap">
          <HStack spacing={4} flexWrap="wrap" justifyContent="center">
            <Icon as={FaCalendarAlt} color="gray.500" />
            <Text color="gray.500" fontSize={{ base: 'sm', md: 'md' }}>
              Publicado el 24 de Octubre, 2024
            </Text>
            <Tag bg={tagBg} color="teal.800">
              Biografía
            </Tag>
          </HStack>
        </Flex>

        {/* Imagen destacada */}
        <Image
          src={post.thumbnail}
          alt={post.title}
          borderRadius="md"
          mb={6}
          objectFit="cover"
          width="100%"
          height={{ base: '250px', md: '400px' }}
          boxShadow="lg"
        />

        {/* Tabla de Contenido */}
        {toc.length > 0 && (
          <Box mb={8}>
            <Heading as="h2" size="lg" mb={2} color={textColor}>
              Tabla de Contenido
            </Heading>
            <List spacing={2} pl={4}>
              {toc.map((item, index) => (
                <ListItem key={index}>
                  <ListIcon as={CheckCircleIcon} color="teal.500" />
                  <ChakraLink href={`#${item.id}`} color={linkColor} fontSize="lg">
                    {item.text}
                  </ChakraLink>
                </ListItem>
              ))}
            </List>
            <Divider mt={4} />
          </Box>
        )}

        {/* Contenido del Post */}
        <Box bg={contentBg} p={6} borderRadius="lg" boxShadow="md">
          {contentWithIds.map((section, index) => {
            switch (section.type) {
              case 'heading':
                return (
                  <Heading
                    key={index}
                    as={`h${section.level}`}
                    size={section.level === 2 ? 'lg' : 'md'}
                    mt={section.level === 2 ? 6 : 4}
                    mb={2}
                    color={textColor}
                    id={section.id}
                  >
                    {section.text}
                  </Heading>
                );
              case 'paragraph':
                return (
                  <Text key={index} mb={4} fontSize={{ base: 'md', md: 'lg' }} lineHeight="taller" color={textColor}>
                    {section.text}
                  </Text>
                );
              case 'image':
                return (
                  <Image
                    key={index}
                    src={section.src}
                    alt={section.alt}
                    borderRadius="md"
                    mb={6}
                    objectFit="cover"
                    width="100%"
                    height="300px"
                    boxShadow="md"
                  />
                );
              case 'list':
                return (
                  <List key={index} spacing={2} pl={6} mb={4}>
                    {section.items.map((item, idx) => (
                      <ListItem key={idx} fontSize={{ base: 'md', md: 'lg' }} color={textColor}>
                        <ListIcon as={CheckCircleIcon} color="teal.500" />
                        {item}
                      </ListItem>
                    ))}
                  </List>
                );
              default:
                return null;
            }
          })}
        </Box>

        <Divider mt={8} />

        {/* Botón de Volver al Blog */}
        <ChakraLink
          as={ChakraLink}
          href="/blog"
          display="flex"
          alignItems="center"
          color={linkColor}
          mt={6}
          fontSize={{ base: 'md', md: 'lg' }}
        >
          <ArrowBackIcon mr={2} /> Volver al Blog
        </ChakraLink>
      </Container>
    </Box>
  );
};

export default Post;
