import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import AppRoutes from './routes/AppRoutes';
import theme from './theme/theme';


function App() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <AppRoutes />
    </ChakraProvider>
  );
}

export default App;
