// src/pages/AboutUs.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Image,
  useColorModeValue,
  SimpleGrid,
  Container,
  Divider,
  Avatar,
  Icon,
  Link,
  Flex,
} from '@chakra-ui/react';
import { FaLinkedin, FaTwitter, FaEnvelope } from 'react-icons/fa';

const teamMembers = [
  {
    name: 'Ana García',
    position: 'CEO & Fundadora',
    image: 'https://placehold.co/150x150', // Placeholder de imagen
    bio: 'Ana ha liderado la creación de Biografai con una visión clara de preservar los recuerdos y legados de las personas de manera innovadora.',
    linkedin: '#',
    twitter: '#',
    email: '#',
  },
  {
    name: 'Carlos Pérez',
    position: 'CTO & Co-Fundador',
    image: 'https://placehold.co/150x150',
    bio: 'Con una pasión por la tecnología, Carlos supervisa el desarrollo tecnológico que impulsa nuestra plataforma de inteligencia artificial.',
    linkedin: '#',
    twitter: '#',
    email: '#',
  },
  {
    name: 'Lucía Pérez',
    position: 'Jefa de Marketing',
    image: 'https://placehold.co/150x150',
    bio: 'Lucía se encarga de llevar el mensaje de Biografai al mundo, ayudando a las personas a descubrir esta herramienta única.',
    linkedin: '#',
    twitter: '#',
    email: '#',
  },
  {
    name: 'Miguel Díaz',
    position: 'Desarrollador Senior',
    image: 'https://placehold.co/150x150',
    bio: 'Miguel se asegura de que la plataforma funcione perfectamente, integrando nuevas funcionalidades y mejoras continuamente.',
    linkedin: '#',
    twitter: '#',
    email: '#',
  },
];

const AboutUs = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const titleColor = useColorModeValue('teal.500', 'teal.300');
  const cardBg = useColorModeValue('white', 'gray.800'); // Definido fuera de la función de renderizado

  return (
    <Box bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">

      <Helmet>
        <title>Sobre Nosotros - Biografai</title>
        <meta name="description" content="Conoce más sobre Biografai, nuestra misión de preservar legados personales mediante biografías creadas con inteligencia artificial." />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Sobre Nosotros - Biografai" />
        <meta property="og:description" content="Descubre cómo Biografai ayuda a preservar legados personales a través de biografías personalizadas." />
        <meta property="og:image" content="%PUBLIC_URL%/images/about-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/aboutus" />
        <meta property="og:type" content="website" />

        {/* Twitter Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sobre Nosotros - Biografai" />
        <meta name="twitter:description" content="Descubre la misión de Biografai de ayudar a preservar legados personales." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/about-twitter-image.jpg" />
      </Helmet>

      <Container maxW="container.lg">
        {/* Sección de encabezado */}
        <VStack spacing={4} textAlign="center" mb={12}>
          <Heading as="h1" size="2xl" color={titleColor}>
            Sobre Nosotros
          </Heading>
          <Text fontSize="lg" color={textColor}>
            En Biografai, creemos en la importancia de preservar los recuerdos y legados de las personas a través de la tecnología. Nuestra misión es proporcionar una plataforma intuitiva y accesible para que cualquier persona pueda crear su propia biografía, incorporando recuerdos, fotos y su propia voz.
          </Text>
        </VStack>

        {/* Sección de historia */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={16}>
          <Box>
            <Image
              src="https://placehold.co/600x400"
              alt="Sobre nosotros"
              borderRadius="lg"
              boxShadow="lg"
              objectFit="cover"
              width="100%"
            />
          </Box>
          <VStack spacing={4} alignItems="flex-start">
            <Heading as="h2" size="lg" color={titleColor}>
              Nuestra Historia
            </Heading>
            <Text fontSize="md" color={textColor}>
              Biografai nació con la idea de ayudar a las personas a crear un legado duradero. Desde nuestra fundación en 2024, hemos desarrollado una plataforma innovadora que permite a cualquiera crear su biografía fácilmente, incorporando multimedia y hasta la propia voz del usuario.
            </Text>
            <Text fontSize="md" color={textColor}>
              A través de nuestra avanzada tecnología de inteligencia artificial, nos hemos convertido en una herramienta de referencia para personas mayores que desean compartir sus historias con familiares y amigos. Creemos que cada vida merece ser contada y recordada.
            </Text>
          </VStack>
        </SimpleGrid>

        <Divider mb={12} />

        {/* Sección de miembros del equipo */}
        <VStack spacing={4} textAlign="center" mb={10}>
          <Heading as="h2" size="xl" color={titleColor}>
            Nuestro Equipo
          </Heading>
          <Text fontSize="lg" color={textColor}>
            Somos un equipo comprometido con la misión de llevar el legado de las personas a las generaciones futuras. Cada miembro de Biografai aporta su talento único para que esto sea posible.
          </Text>
        </VStack>

        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          {teamMembers.map((member, index) => (
            <Flex
              key={index}
              direction="column"
              p={6}
              borderWidth="1px"
              borderRadius="lg"
              boxShadow="lg"
              bg={cardBg}
              height="100%"
              justifyContent="space-between" // Distribuye el contenido para que se alinee correctamente
              _hover={{
                transform: 'scale(1.01)',
                transition: 'transform 0.3s ease-in-out',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
              }}
            >
              <VStack spacing={4} flex="1" align="center">
                <Avatar src={member.image} alt={member.name} size="xl" mb={4} />
                <Heading as="h3" size="md" color={titleColor}>
                  {member.name}
                </Heading>
                <Text fontWeight="bold" color={textColor}>
                  {member.position}
                </Text>
                <Text fontSize="sm" color={textColor} textAlign="center">
                  {member.bio}
                </Text>
              </VStack>
              <HStack spacing={4} mt={4} justify="center">
                <Link href={member.linkedin} isExternal>
                  <Icon as={FaLinkedin} w={6} h={6} color="teal.500" />
                </Link>
                <Link href={member.twitter} isExternal>
                  <Icon as={FaTwitter} w={6} h={6} color="teal.500" />
                </Link>
                <Link href={member.email} isExternal>
                  <Icon as={FaEnvelope} w={6} h={6} color="teal.500" />
                </Link>
              </HStack>
            </Flex>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default AboutUs;
