import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Collapse,
  Image,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';

const Links = [
  { name: 'Inicio', path: '/' },
  { name: 'Precios', path: '/prices' },
  { name: 'Sobre nosotros', path: '/aboutus' },
  { name: 'Blog', path: '/blog' },
  { name: 'FAQs', path: '/faq' },
];

const NavLink = ({ name, path }) => (
  <Link
    px={2}
    py={1}
    rounded="md"
    href={path}
    fontWeight="medium"
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
  >
    {name}
  </Link>
);

const Navbar = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      opacity="95%"
      px={4}
      boxShadow="md"
      position="sticky"
      top={0}
      zIndex={1}
    >
      <Flex
        h={16}
        alignItems="center"
        justifyContent="space-between"
        maxW="6xl"
        mx="auto"
      >
        {/* Sección Izquierda: Logo y Enlaces */}
        <HStack spacing={8} alignItems="center">
          {/* Logo */}
          <Link href="/" _hover={{ textDecoration: 'none' }}>
            <Image
              src="https://via.placeholder.com/40"
              alt="Logo"
              boxSize="40px"
            />
          </Link>

          {/* Enlaces de navegación */}
          <HStack
            as="nav"
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {Links.map((link) => (
              <NavLink key={link.name} name={link.name} path={link.path} />
            ))}
          </HStack>
        </HStack>

        {/* Sección Derecha: Botones e Iconos */}
        <HStack spacing={4} alignItems="center">
          <Button
            variant="ghost"
            size="sm"
            display={{ base: 'none', md: 'inline-flex' }}
          >
            Iniciar Sesión
          </Button>
          <Button
            colorScheme="teal"
            size="sm"
            display={{ base: 'none', md: 'inline-flex' }}
          >
            Registrarse
          </Button>
          <IconButton
            size="md"
            variant="ghost"
            onClick={toggleColorMode}
            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
            aria-label="Cambiar Tema"
          />
          {/* Menú de hamburguesa para móviles */}
          <IconButton
            size="md"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Abrir Menú"
            display={{ md: 'none' }}
            onClick={onToggle}
          />
        </HStack>
      </Flex>

      {/* Menú desplegable en dispositivos móviles */}
      <Collapse in={isOpen} animateOpacity>
        <Box pb={4} display={{ md: 'none' }}>
          <Stack as="nav" spacing={4}>
            {Links.map((link) => (
              <NavLink key={link.name} name={link.name} path={link.path} />
            ))}
            <Button variant="ghost" w="full">
              Iniciar Sesión
            </Button>
            <Button colorScheme="teal" w="full">
              Registrarse
            </Button>
          </Stack>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Navbar;
