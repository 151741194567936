// src/pages/Pricing.js
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Heading,
  Text,
  SimpleGrid,
  VStack,
  useColorModeValue,
  Container,
  HStack,
  Icon,
  Badge,
  Divider,
  Link,
  Circle,
} from '@chakra-ui/react';
import { FaCheckCircle, FaRocket, FaStar, FaHeadset, FaGift } from 'react-icons/fa';

const Pricing = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const headingColor = useColorModeValue('teal.600', 'teal.300');
  const priceBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Box bg={bgColor} py={16} px={4} display="flex" flexDirection="column" flex="1">
      
      <Helmet>
        <title>Planes y Precios - Biografai</title>
        <meta name="description" content="Descubre los planes y precios de Biografai para crear y personalizar tu biografía." />
        
        {/* Open Graph */}
        <meta property="og:title" content="Planes y Precios - Biografai" />
        <meta property="og:description" content="Conoce los planes flexibles que Biografai ofrece para crear y preservar tu historia." />
        <meta property="og:image" content="%PUBLIC_URL%/images/prices-og-image.jpg" />
        <meta property="og:url" content="https://www.biografai.com/prices" />
        <meta property="og:type" content="website" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Planes y Precios - Biografai" />
        <meta name="twitter:description" content="Explora los planes y precios de Biografai y elige el ideal para tu biografía." />
        <meta name="twitter:image" content="%PUBLIC_URL%/images/prices-twitter-image.jpg" />
      </Helmet>

      <Container maxW="container.lg">
        {/* Encabezado */}
        <VStack spacing={4} textAlign="center" mb={12}>
          <Heading as="h1" size="2xl" color={headingColor}>
            Planes de Suscripción
          </Heading>
          <Text fontSize="lg" color={textColor}>
            Elige el plan que mejor se adapte a tus necesidades. Ofrecemos opciones flexibles tanto mensuales como anuales.
          </Text>
        </VStack>

        {/* Grilla de Precios */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          {/* Plan Mensual */}
          <Box
            bg={priceBgColor}
            boxShadow="xl"
            borderRadius="lg"
            p={8}
            textAlign="center"
            transition="all 0.3s"
            _hover={{ transform: 'scale(1.01)', shadow: '2xl' }}
          >
            <Badge colorScheme="teal" mb={4} fontSize="1em">
              Más Popular
            </Badge>
            <Heading as="h2" size="xl" mb={4} color={headingColor}>
              Suscripción Mensual
            </Heading>
            <Text fontSize="4xl" fontWeight="bold" mb={4}>
              $10 <Text as="span" fontSize="lg" color={textColor}>/mes</Text>
            </Text>
            <Text fontSize="md" color={textColor} mb={6}>
              Accede a todas nuestras funciones con pagos mensuales flexibles. Perfecto si deseas una opción sin compromisos a largo plazo.
            </Text>

            <VStack spacing={4} align="start" mb={6}>
              <HStack>
                <Icon as={FaCheckCircle} color="teal.500" />
                <Text color={textColor}>Acceso completo a todas las características</Text>
              </HStack>
              <HStack>
                <Icon as={FaCheckCircle} color="teal.500" />
                <Text color={textColor}>Soporte prioritario</Text>
              </HStack>
              <HStack>
                <Icon as={FaCheckCircle} color="teal.500" />
                <Text color={textColor}>Cancelación en cualquier momento</Text>
              </HStack>
            </VStack>

            <Button colorScheme="teal" size="lg" width="full">
              Elegir Plan Mensual
            </Button>
          </Box>

          {/* Plan Anual */}
          <Box
            bg={priceBgColor}
            boxShadow="xl"
            borderRadius="lg"
            p={8}
            textAlign="center"
            transition="all 0.3s"
            _hover={{ transform: 'scale(1.01)', shadow: '2xl' }}
          >
            <Badge colorScheme="purple" mb={4} fontSize="1em">
              Mejor Valor
            </Badge>
            <Heading as="h2" size="xl" mb={4} color={headingColor}>
              Pago Anual
            </Heading>
            <Text fontSize="4xl" fontWeight="bold" mb={4}>
              $100 <Text as="span" fontSize="lg" color={textColor}>/año</Text>
            </Text>
            <Text fontSize="md" color={textColor} mb={6}>
              Disfruta de un ahorro significativo con un único pago anual. Ideal para quienes buscan el mejor valor a largo plazo.
            </Text>

            <VStack spacing={4} align="start" mb={6}>
              <HStack>
                <Icon as={FaCheckCircle} color="purple.500" />
                <Text color={textColor}>Acceso completo a todas las características</Text>
              </HStack>
              <HStack>
                <Icon as={FaCheckCircle} color="purple.500" />
                <Text color={textColor}>Soporte prioritario 24/7</Text>
              </HStack>
              <HStack>
                <Icon as={FaCheckCircle} color="purple.500" />
                <Text color={textColor}>Ahorra un 20% comparado con el plan mensual</Text>
              </HStack>
            </VStack>

            <Button colorScheme="purple" size="lg" width="full">
              Elegir Plan Anual
            </Button>
          </Box>
        </SimpleGrid>

        <Divider my={12} />

        {/* Beneficios Adicionales con Iconos Redondeados */}
        <VStack spacing={4} textAlign="center" mb={12}>
          <Heading as="h3" size="lg" color={headingColor}>
            Beneficios Exclusivos
          </Heading>
          <Text fontSize="lg" color={textColor}>
            Disfruta de los siguientes beneficios adicionales al unirte a nuestra comunidad de suscriptores.
          </Text>
        </VStack>

        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={8}>
          <VStack spacing={3}>
            <Circle size="70px" bg="teal.500" color="white">
              <Icon as={FaRocket} w={8} h={8} />
            </Circle>
            <Text fontWeight="bold" fontSize="lg" color={headingColor}>Actualizaciones</Text>
            <Text color={textColor} textAlign="center">Accede a las últimas mejoras y funciones automáticamente.</Text>
          </VStack>
          <VStack spacing={3}>
            <Circle size="70px" bg="teal.500" color="white">
              <Icon as={FaStar} w={8} h={8} />
            </Circle>
            <Text fontWeight="bold" fontSize="lg" color={headingColor}>Funciones Exclusivas</Text>
            <Text color={textColor} textAlign="center">Disfruta de funciones exclusivas disponibles solo para suscriptores.</Text>
          </VStack>
          <VStack spacing={3}>
            <Circle size="70px" bg="teal.500" color="white">
              <Icon as={FaHeadset} w={8} h={8} />
            </Circle>
            <Text fontWeight="bold" fontSize="lg" color={headingColor}>Soporte Prioritario</Text>
            <Text color={textColor} textAlign="center">Obtén acceso a soporte dedicado y prioritario las 24 horas.</Text>
          </VStack>
          <VStack spacing={3}>
            <Circle size="70px" bg="teal.500" color="white">
              <Icon as={FaGift} w={8} h={8} />
            </Circle>
            <Text fontWeight="bold" fontSize="lg" color={headingColor}>Pruebas Gratuitas</Text>
            <Text color={textColor} textAlign="center">Accede a futuras versiones y pruebas antes que el público.</Text>
          </VStack>
        </SimpleGrid>

        <Divider my={12} />

        {/* Preguntas Frecuentes */}
        <VStack spacing={4} textAlign="center">
          <Heading as="h3" size="lg" color={headingColor}>
            Preguntas Frecuentes
          </Heading>
          <Text fontSize="lg" color={textColor}>
            ¿Tienes alguna duda? Aquí respondemos las preguntas más comunes sobre nuestros planes.
          </Text>
          <Link href="/faq" color="teal.500" fontWeight="bold">
            Visita nuestra página de FAQs.
          </Link>
        </VStack>

      </Container>
    </Box>
  );
};

export default Pricing;
