// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Text,
  useDisclosure,
  Icon,
  Stack,
  Link,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { FaCookieBite } from 'react-icons/fa';
import { useLocation } from 'react-router-dom'; // Importamos el hook para obtener la ruta actual

const CookieConsent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  
  // Obtenemos el ancho máximo basado en el tamaño de la pantalla
  const modalMaxWidth = useBreakpointValue({ base: '90%', md: 'lg' });

  // Usamos useLocation para obtener la ruta actual
  const location = useLocation();

  // Verificamos si el usuario ha aceptado las cookies
  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    
    // No mostrar el modal si estamos en la página /cookie-policy o si ya ha aceptado las cookies
    if (!hasAcceptedCookies && location.pathname !== '/cookie-policy') {
      onOpen(); // Mostramos el modal si no ha aceptado y no está en la página de política
    } else {
      setCookiesAccepted(true);
    }
  }, [onOpen, location.pathname]);

  // Función para aceptar las cookies
  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
    onClose(); // Cierra el modal
  };

  // Función para rechazar las cookies (puedes personalizar su acción)
  const handleRejectCookies = () => {
    onClose(); // Cierra el modal, pero no guarda la aceptación
  };

  return (
    <>
      {!cookiesAccepted && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent
            bg="gray.900"
            color="white"
            borderRadius="lg"
            p={6}
            boxShadow="xl"
            maxW={modalMaxWidth} // Ancho dinámico según el tamaño de la pantalla
            mx={4} // Margen horizontal para evitar que esté pegado a los extremos en pantallas pequeñas
          >
            <ModalHeader>
              <Stack direction="row" align="center" spacing={4}>
                <Icon as={FaCookieBite} w={10} h={10} color="yellow.400" />
                <Text fontSize="2xl" fontWeight="bold">
                  Consentimiento de Cookies
                </Text>
              </Stack>
            </ModalHeader>

            <ModalBody>
              <Text fontSize="md" mb={4}>
                Usamos cookies para mejorar tu experiencia en nuestro sitio web. Al aceptar, nos
                ayudas a ofrecerte una navegación más personalizada. Puedes leer más en nuestra{' '}
                <Link href="/cookie-policy" color="teal.300">
                  Política de Cookies
                </Link>.
              </Text>

              <Box p={4} bg="gray.700" borderRadius="md" textAlign="center">
                <Text fontSize="lg" fontStyle="italic">
                  "¡La mejor experiencia siempre viene acompañada de unas cookies!"
                </Text>
              </Box>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4} justify="flex-end" width="100%">
                <Button
                  onClick={handleRejectCookies}
                  variant="outline"
                  colorScheme="red"
                  borderColor="red.500"
                  color="red.400"
                  _hover={{ bg: 'red.500', color: 'white' }}
                >
                  Rechazar
                </Button>
                <Button
                  onClick={handleAcceptCookies}
                  colorScheme="teal"
                  _hover={{ bg: 'teal.400' }}
                >
                  Aceptar Cookies
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CookieConsent;
