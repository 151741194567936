import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  useColorModeValue,
  Drawer,
  DrawerContent,
  useDisclosure,
  Text,
  Link,
  Icon,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode
} from '@chakra-ui/react';
import {
  FiMenu,
  FiHome,
  FiSettings,
  FiBell,
  FiUser,
  FiLogOut,
  FiSun,
  FiMoon,
} from 'react-icons/fi';
import SettingsModal from './SettingsModal'

const Dashboard = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Estado para controlar el modal de ajustes
  const {
    isOpen: isSettingsOpen,
    onOpen: onSettingsOpen,
    onClose: onSettingsClose,
  } = useDisclosure();

  // Variables de color adaptativas al modo claro/oscuro
  const bgColor = useColorModeValue('gray.100', 'gray.900');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Box minH="100vh" bg={bgColor}>
      <SidebarContent
        onClose={onClose}
        onSettingsOpen={onSettingsOpen} // Pasamos el handler
        display={{ base: 'none', md: 'block' }}
      />
      {/* Drawer para dispositivos móviles */}
      <Drawer autoFocus={false} isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            onSettingsOpen={onSettingsOpen} // Pasamos el handler
          />
        </DrawerContent>
      </Drawer>
      {/* Contenido principal */}
      <Flex direction="column" ml={{ base: 0, md: 60 }}>
        <DashboardNavbar onOpen={onOpen} />
        <Box p={6}>
          <Text fontSize="2xl" fontWeight="bold" mb={6} color={textColor}>
            Bienvenido al Dashboard
          </Text>
          {/* Aquí puedes agregar contenido adicional */}
        </Box>
      </Flex>
      {/* Modal de Ajustes */}
      <SettingsModal isOpen={isSettingsOpen} onClose={onSettingsClose} />
    </Box>
  );
};

const SidebarContent = ({ onClose, onSettingsOpen, ...rest }) => {
  const sidebarBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const LinkItems = [
    { name: 'Inicio', icon: FiHome, path: '/' },
    { name: 'Perfil', icon: FiUser, path: '/dashboard/profile' },
    { name: 'Ajustes', icon: FiSettings, action: onSettingsOpen }, // Usamos action en lugar de path
    // Agrega más enlaces según sea necesario
  ];

  return (
    <Box
      bg={sidebarBg}
      borderRight="1px"
      borderRightColor={borderColor}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="2xl" fontWeight="bold">
          Mi Dashboard
        </Text>
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onClose}
          variant="ghost"
          aria-label="Cerrar menú"
          icon={<FiMenu />}
        />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          path={link.path}
          action={link.action} // Pasamos la acción si existe
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, path, action, ...rest }) => {
  const linkColor = useColorModeValue('gray.600', 'gray.300');
  const linkHoverBg = useColorModeValue('gray.200', 'gray.700');
  const linkHoverColor = useColorModeValue('gray.900', 'white');

  const handleClick = (e) => {
    if (action) {
      e.preventDefault(); // Prevenimos la navegación si hay una acción
      action();
    }
  };

  return (
    <Link
      href={path || '#'}
      onClick={handleClick}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={linkColor}
        _hover={{
          bg: linkHoverBg,
          color: linkHoverColor,
        }}
        {...rest}
      >
        {icon && (
          <Icon mr="4" fontSize="16" as={icon} _groupHover={{ color: 'teal.400' }} />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const DashboardNavbar = ({ onOpen, ...rest }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const navbarBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const iconColor = useColorModeValue('gray.600', 'gray.300');
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Flex
      as="header"
      position="sticky"
      top="0"
      zIndex="1"
      alignItems="center"
      justifyContent="space-between"
      bg={navbarBg}
      borderBottomWidth="1px"
      borderBottomColor={borderColor}
      h="16"
      px="4"
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="ghost"
        aria-label="Abrir menú"
        icon={<FiMenu />}
      />
      <Text fontSize="lg" fontWeight="bold" display={{ base: 'none', md: 'block' }}>
        Panel de Control
      </Text>
      <HStack spacing="4">
        <IconButton
          variant="ghost"
          aria-label="Cambiar tema"
          icon={colorMode === 'light' ? <FiMoon /> : <FiSun />}
          onClick={toggleColorMode}
          color={iconColor}
        />
        <IconButton variant="ghost" aria-label="Notificaciones" icon={<FiBell />} color={iconColor} />
        <Menu>
          <MenuButton>
            <Avatar size="sm" src="https://via.placeholder.com/150" name="Usuario" />
          </MenuButton>
          <MenuList bg={navbarBg} borderColor={borderColor}>
            <MenuItem icon={<FiUser />} color={textColor}>
              Perfil
            </MenuItem>
            <MenuItem icon={<FiSettings />} color={textColor}>
              Configuración
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<FiLogOut />} color={textColor}>
              Cerrar Sesión
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};

export default Dashboard;
